<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="m-1 p-0 w-100">
        <b-card-title class="mb-1">
          Two-Factor Authentication 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Please open your Microsoft / Google Authenticator App that you have setup and input the OTP
        </b-card-text>

        <!-- form -->
        <div class="mt-2 d-flex align-items-center justify-content-center">
          <TwoFactorAuthenticationInput
            v-model="otp"
          />
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'
import TwoFactorAuthenticationInput from '@/components/two-factor-authenticator/TwoFactorAuthenticationInput.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    SpinnerBtn,
    ValidationProvider,
    ValidationObserver,

    TwoFactorAuthenticationInput,
  },
  props: {
    title: { type: String, default: null },
    isHiringManager: { type: Boolean, default: false },
    whichPage: { type: String, default: 'normal' },
  },
  data() {
    return {
      show: false,
      otp: null,
      isLoading: false,

      required,
    }
  },
  computed: {},

  watch: {
    otp(newVal) {
      if (this.$isNotEmpty(newVal)) {
        if (newVal.length === 6) {
          this.verifyOTP()
        }
      }
    },
  },

  created() {},

  methods: {
    verifyOTP() {
      this.$http
        .post('/api/auth/2fa-verify-otp-login', {
          form_data: {
            otp: this.otp,
            '2fa_page_code': localStorage.getItem('2fa_page_code'),
            login_id: localStorage.getItem('login_id'),
          }
        })
        .then(response => {
          const { message, output, success } = response.data

          if (success) {
            localStorage.removeItem('email_address')
            localStorage.removeItem('2fa_page_code')
            const response = {
              output,
            }

            // this.$refreshUserInfo(this)
            useJwt.setToken(output.token)
            this.$emit('login-state', true)
            // localStorage.setItem('accessToken', userData.token) // Update user details
            this.$store.commit('auth/UPDATE_USER_INFO', output, { root: true }) // Set bearer token in axios
            this.$toastSuccess('2FA Operation', '2FA verification success')

            let link = '/'
            if (
              output.account_type === this.$AccountTypes.EMPLOYER
              || output.account_type === this.$AccountTypes.TEMPORARY
            ) {
              link = '/events-manager/events'
            }
            // else if (appliedJob) {
            //   link = events/${appliedJob._id}?quiz=true`
            // }
            if (this.whichPage !== 'normal') {
              // this.$router.push(this.$router.currentRoute.query.redirect || link)
              if (this.$route.name.includes('listings-page') || this.$route.name === 'page-event') {
                this.$emit('user-logged-in')
              }
            } else if (this.$route.name !== 'app-login') {
              this.$router.push(this.$router.currentRoute.query.redirect || link)
            } else {
              this.$router.push(`${this.$route.fullPath}&approved=true`)
            }
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
